<i18n>
{
  "en": {
    "upgrade": "Upgrade",
    "join": "Join",
    "reviews": "User Reviews"
  },
  "ja": {
    "upgrade": "アップグレード",
    "join": "入会する",
    "reviews": "ユーザーレビュー"
  }
}
</i18n>

<template>
  <main>
    <vue-headful
      :title="dynamicTitle"
      :head="{
          'meta[name=\'robots\']': {
            name: 'robots',
            content: (guestExcluded) ? 'noindex' : 'index'
          },
      }" />
    <div id="movies" class="page-margin">
      <div class="movie-container">
        <div class="inner-container">
          <!-- video player -->
          <video-player v-if="!guestExcluded" :check-date="1488931205" :key="componentRefresh" />

          <!-- playlist menu -->
          <playlist-menu v-if="playlistEnabled" />

          <div class="movie-contents">
            <!-- movie info -->
            <movie-info :hide-release-date="hideOldReleaseDate" />
            <!-- aff embed -->
            <div v-if="hasCensoredSample">
              <aff-embed />
            </div>
            <!-- movie download -->
            <download-menu v-if="!newXPEligible" />
            <!-- movie gallery -->
            <movie-gallery />
            <!-- movie reviews (only in jp site) -->
            <div class="movie-review section" v-if="locale == 'ja'">
              <h2 class="is-primary">{{ $t('reviews') }}</h2>
              <review-form />
              <review-list />
            </div>
          </div><!-- /.movie-contents -->
        </div><!-- /.inner-container -->

        <div class="sidebar">
          <!-- related movies -->
          <related-movies :num-movies="12" />
        </div><!-- /.sidebar -->
      </div><!-- /.movie-container -->
    </div><!-- /#movies -->
  </main>
</template>

<script>
/* eslint max-len: 0 */

import vueHeadful from 'vue-headful';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import MovieHelper from '@/assets/js/utils/movie';
import APP_CONFIG from '@/appConfig';
import VideoPlayer from '@/components/movieDetail/videoPlayer.vue';
import PlaylistMenu from '@/components/movieDetail/playlistMenu.vue';
import MovieInfo from '@/components/movieDetail/movieInfo.vue';
import AffEmbed from '@/components/movieDetail/affEmbed.vue';
import DownloadMenu from '@/components/movieDetail/downloadMenu.vue';
import MovieGallery from '@/components/movieDetail/movieGallery.vue';
import ReviewForm from '@/components/movieDetail/reviewForm.vue';
import ReviewList from '@/components/movieDetail/reviewList.vue';
import RelatedMovies from '@/components/movieDetail/relatedMovies.vue';

export default {
  components: {
    'vue-headful': vueHeadful,
    'video-player': VideoPlayer,
    'playlist-menu': PlaylistMenu,
    'movie-info': MovieInfo,
    'download-menu': DownloadMenu,
    'movie-gallery': MovieGallery,
    'review-form': ReviewForm,
    'review-list': ReviewList,
    'related-movies': RelatedMovies,
    'aff-embed': AffEmbed,
  },
  data() {
    return {
      movie: {},
      dynamicTitle: '',

      componentRefresh: 0,
    };
  },
  async mounted() {
    await this.getMovie(this.userTypeID);
  },
  methods: {
    async getMovie(userTypeID) {
      const bfAPI = new BifrostAPI();

      const movie = await bfAPI.getMovieDetail(this.$route.params.movieId);
      this.movie = MovieHelper.expandDetails(movie, this.locale, userTypeID);

      // redirect to home if the movie's no_list_display flag is set
      if (Object.prototype.hasOwnProperty.call(this.movie, 'NoListDisplay')
        && this.movie.NoListDisplay) this.$router.push({ name: 'home' });

      // set browser title
      const actors = (this.locale === 'en') ? this.movie.ActressesEn.join() : this.movie.ActressesJa.join();
      const title = (this.locale === 'en') ? this.movie.TitleEn : this.movie.Title;
      this.dynamicTitle = `${actors} ${title} ${APP_CONFIG.site.title[this.locale]}`;

      // we have the title, now send the page view
      this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
    },

    forceRerender() {
      // setting a reactive counter hooked up to the `key` attribute for the video-plahyer
      // component in the template. vue knows to re-render a component if it has a `key` attribute
      // bound to a reactive value, so we're taking advantage of this in order to force the player
      // to refresh whenever the user logs in -- this fixes a bug where two GA video player events
      // would be sent if a user logs in (or out) while on a movie detail page
      // (from https://michaelnthiessen.com/force-re-render/
      setTimeout(() => {
        this.componentRefresh += 1;
      }, 650);
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
    guestExcluded() {
      return (this.userTypeID === 0 && this.movie.isGuestExcluded === true);
    },
    hasCensoredSample() {
      const releaseEpoch = Date.parse(this.movie.Release) / 1000;
      return (releaseEpoch >= 1489622400); // march 6 2017
    },
    playlistEnabled() {
      return this.$store.getters['playlist/isEnabled'];
    },
    hideOldReleaseDate() {
      const releaseDate = new Date(this.movie.Release);
      const currentDate = new Date();
      const differenceInMsec = currentDate - releaseDate;
      const differenceInDays = differenceInMsec / (1000 * 60 * 60 * 24); // milliseconds to days
      return (differenceInDays > APP_CONFIG.site.releaseDateHiddenAge);
    },
  },
  watch: {
    userTypeID(newVal) {
      this.getMovie(newVal);
      this.forceRerender();
    },
  },
};
</script>

<style lang="scss" scoped>
// this block needs to be 'scoped' because one of the global styles is causing the timeslider
// previews to not render correctly (shows the entire sprite instead of the frame)
@import '@/assets/sass/pages/_detail.scss';
</style>
