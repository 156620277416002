<template>
  <div class="player">
    <div class="player-ratio">
      <div class="player-image" v-sfw>
        <!--
          show only movie poster (don't even load the video player) if any of the folowing is true:
            - movie is expired
            - memberFiles data is missing from the details json
            - oldMovie && user is not a member
            - movie is VR and user is a streaming or guest user type
        -->
        <img
          v-if="locale == 'ja' && movieDetail.MovieID && (movieDetail.IsExpired || !movieDetail.MemberFiles || (oldMovie && !userIsSiteMember) || showVRPoster)"
          :src="movieDetail.posterSrc">
        <img
          v-else-if="locale == 'en' && movieDetail.MovieID && (movieDetail.IsExpired || !movieDetail.MemberFiles || (oldMovie && !userIsSiteMember) || showVRPoster)"
          :src="movieDetail.posterSrc" @error="enFallbackImage()">
        <video v-else class="video-js vjs-big-play-centered" id="video-player-0" playsinline>
          <track kind="metadata" :src="`/assets/ts/${movieDetail.MovieID}/thumbnail.vtt`" />
        </video>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-undef: 0 */
/* eslint max-len: 0 */
/* eslint no-unused-vars: 0 */

import BifrostAPI from '@/assets/js/services/Bifrost/API';
import VideoJS from '@/assets/js/services/Video/VideoJS';
import PlayerFactory from '@/assets/js/services/Video/PlayerFactory';
import MovieHelper from '@/assets/js/utils/movie';
import APP_CONFIG from '@/appConfig';

const VR_CATEGORY_ID = 94;

export default {
  props: {
    checkDate: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      videoPlayerInstance: null,
      movieDetail: {},
      oldMovie: false,
      hdPoster: false,
      enPosterFallbackTry: false,
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    showVRPoster() {
      return (this.$store.getters['user/isNewXPEligible']
        && this.movieDetail.UC.includes(VR_CATEGORY_ID)
        && process.env.VUE_APP_NEW_XP_HIDE_VR === 'true');
    },
    userIsSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
    userTypeStr() {
      return this.$store.getters['user/typeStr'];
    },
    userIsRecurring() {
      return this.$store.getters['user/isRecurring'];
    },
    userIsAnnual() {
      return this.$store.getters['user/isAnnual'];
    },
    userIsTrial() {
      return this.$store.getters['user/isTrial'];
    },
  },

  // note: reloading of the video player is now handled on the component containing this, in order
  // to fix timing issues with login + video player initializing causing double video player events
  // being sent to GA (due to the player initializing twice for logging in at specific times during
  // the page loading cycle)

  methods: {
    playerInstanceFactory(movieData) {
      const userData = {
        typeID: this.userTypeID,
        typeStr: this.userTypeStr,
        isSiteMember: this.userIsSiteMember,
        isTrial: this.userIsTrial,
        isRecurring: this.userIsRecurring,
        isAnnual: this.userIsAnnual,
      };
      PlayerFactory.register('VideoJS', () => new VideoJS('video-player-0', movieData, userData, this.locale, APP_CONFIG.site.enThumbsStartEpoch));

      const init = (Factory) => {
        this.videoPlayerInstance = Factory.create('VideoJS');
        // player class methods can be accessed here.
        // example (assuming ack() method in the videojs class): videoPlayerInstance.ack('hello');
      };
      init(PlayerFactory);
    },

    enFallbackImage() {
      if (!this.enPosterFallbackTry) {
        // attempt 1: poster_en.jpg is missing, use l_hd.jpg
        this.$set(this.movieDetail, 'posterSrc', `/assets/sample/${this.movieDetail.MovieID}/l_hd.jpg`);
        this.enPosterFallbackTry = true;
      } else {
        // attempt 2: l_hd.jpg is missing, use l.jpg
        this.$set(this.movieDetail, 'posterSrc', `/assets/sample/${this.movieDetail.MovieID}/l.jpg`);
      }
    },
  },
  async mounted() {
    // get playlist callback, this fires first then calls the player instance factory to start a new
    // video player instance
    const bfAPI = new BifrostAPI();

    const movie = await bfAPI.getMovieDetail(this.$route.params.movieId);
    this.movieDetail = MovieHelper.expandDetails(movie);

    // check if we're passing a check-date param; in which we need to not show sample playback
    // movies for non-members, and make it appear like an expired movie (only show the movie poster)
    if (this.checkDate) {
      const releaseEpoch = Date.parse(this.movieDetail.Release) / 1000;
      this.oldMovie = (releaseEpoch < Number(this.checkDate));
    }

    // r2723: movie posters naming varies
    // - l.jpg: for older movies; all movies have this image
    // - l_hd.jpg: for newer movies; only newer movies have this image
    // - poster_en.jpg: for en site; not all movies have this image
    // jp: l_hd.jpg > l.jpg; en: poster_en.jpg > l_hd.jpg > l.jpg

    if (this.movieDetail.ThumbHigh.match('l_hd.jpg')) this.hdPoster = true;

    // set intial movie poster image as 'posterSrc'
    if (this.locale === 'ja') {
      if (this.hdPoster) {
        this.$set(this.movieDetail, 'posterSrc', `/assets/sample/${this.movieDetail.MovieID}/l_hd.jpg`);
      } else {
        this.$set(this.movieDetail, 'posterSrc', `/assets/sample/${this.movieDetail.MovieID}/l.jpg`);
      }
    } else {
      this.$set(this.movieDetail, 'posterSrc', `/assets/sample/${this.movieDetail.MovieID}/poster_en.jpg`);
    }

    this.playerInstanceFactory(this.movieDetail);
  },
  beforeDestroy() {
    // remove videoplayer instance before leaving the page
    if (this.videoPlayerInstance) this.videoPlayerInstance.remove();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/pages/_detail.scss';
</style>
