<i18n>
{
  "en": {
    "embedded_player": "Embed",
    "affiliate_id": "Affiliate ID",
    "player_size": "Player Size",
    "video_embed": "Embed Video",
    "video_embed_censored": "Embed Video (censored)",
    "image_embed": "Embed Image",
    "embed_code": "Embed Code",
    "copy_to_clipboard": "Copy",
    "copied_notification": "Copied to clipboard"
  },
  "ja": {
    "embedded_player": "埋め込みプレーヤー",
    "affiliate_id": "アフィリエイトID",
    "player_size": "プレーヤーサイズの設定",
    "video_embed": "無修正動画プレイヤーの埋め込みコード",
    "video_embed_censored": "モザイク動画プレイヤーの埋め込みコード",
    "image_embed": "画像の埋め込みコード",
    "embed_code": "埋め込みコード",
    "copy_to_clipboard": "クリップボードにコピー",
    "copied_notification": "コピーしました"
  }
}
</i18n>

<template>
  <div class="movie-extra">
    <hr>
    <div>
      <div class="extra-item">
        <button class="button is-flat" @click="toggleForm()"><i class="iconf-share is-left"></i>{{ $t('embedded_player') }}</button>
      </div>
      <transition name="aff-embed">
        <div v-if="formVisible" class="embed-contents">
          <h3>{{ $t('affiliate_id') }}</h3>
          <input type="text" v-model="affID" class="login-form-input">
          <transition name="aff-embed">
            <div v-if="affID !== ''">
              <h3>{{ $t('player_size') }}</h3>
              <vue-slider v-model="embedWidth" v-bind="sliderOptions"></vue-slider>
              <vue-tabs type="pills" @tab-change="tabChangeHandler">
                <!-- uncensored player embed -->
                <v-tab v-if="hasUncensoredSample" :title="$t('video_embed')">
                  <div id="embedPlayer">
                    <div :id="`div0${movieID}`" style="position: relative;">
                      <a :style="`display:block; position:absolute; z-index:2; top:0; left:0; text-align:right; padding:0; width:${embedWidth}px; height:${embedHeight - 70}px`" :href="`http://click.dtiserv2.com/Direct/8881886-${this.affSiteID}-${this.affID}/movies/${movieID}/`" target="_blank" rel="noopener">
                        <img style="padding:1em 1em 0;" src="/img/common/embed-logo.png">
                      </a>
                      <video :id="`affvideo${movieID}`" :src="this.sampleMovieURL" :poster="(locale === 'en') ? `https://${hostname}/assets/sample/${movieID}/poster_en.jpg` : `https://${hostname}/assets/sample/${movieID}/l_hd.jpg`" :width="embedWidth" :height="embedHeight" type="video/mp4" controls=""></video>
                    </div>
                  </div>
                  <h3>{{ $t('embed_code') }}</h3>
                  <div>
                    <div class="embed-code">{{ moviePlayerHTML(false) }}</div>
                    <button class="button is-filled is-small is-color-main" @click="copyToClipboard(moviePlayerHTML(false))">{{ $t('copy_to_clipboard') }}</button>
                  </div>
                </v-tab>
                <!-- censored player embed -->
                <v-tab :title="$t('video_embed_censored')">
                  <div id="embedPlayer">
                    <div :id="`div0${movieID}`" style="position: relative;">
                      <a :style="`display:block; position:absolute; z-index:2; top:0; left:0; text-align:right; padding:0; width:${embedWidth}px; height:${embedHeight - 70}px`" :href="`http://click.dtiserv2.com/Direct/8881886-${this.affSiteID}-${this.affID}/movies/${movieID}/`" target="_blank" rel="noopener">
                        <img style="padding:1em 1em 0;" src="/img/common/embed-logo.png">
                      </a>
                      <video :id="`affvideo${movieID}`" :src="this.sampleCensoredMovieURL" :poster="(locale === 'en') ? `https://${hostname}/assets/sample/${movieID}/poster_en.jpg` : `https://${hostname}/assets/sample/${movieID}/l_hd.jpg`" :width="embedWidth" :height="embedHeight" type="video/mp4" controls=""></video>
                    </div>
                  </div>
                  <h3>{{ $t('embed_code') }}</h3>
                  <div>
                    <div class="embed-code">{{ moviePlayerHTML(true) }}</div>
                    <button class="button is-filled is-small is-color-main" @click="copyToClipboard(moviePlayerHTML(true))">{{ $t('copy_to_clipboard') }}</button>
                  </div>
                </v-tab>
                <!-- image embed -->
                <v-tab :title="$t('image_embed')">
                  <img :src="`//${hostname}/assets/sample/${movieID}/l_hd.jpg`" :width="embedWidth">
                  <h3>{{ $t('embed_code') }}</h3>
                  <div>
                    <div class="embed-code">{{ imageBannerHTML }}</div>
                    <button class="button is-filled is-small is-color-main" @click="copyToClipboard(imageBannerHTML)">{{ $t('copy_to_clipboard') }}</button>
                  </div>
                </v-tab>
              </vue-tabs>
            </div>
          </transition>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
/* eslint max-len: 0 */
/* eslint no-useless-escape: 0 */
import { VueTabs, VTab } from 'vue-nav-tabs';
import vueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import APP_CONFIG from '@/appConfig';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import EventBus from '@/assets/js/utils/EventBus';

export default {
  components: {
    VueTabs,
    VTab,
    vueSlider,
  },

  data() {
    return {
      movieID: this.$route.params.movieId,
      affSiteID: 320,

      affID: '',
      embedRatio: 1.66281755196305, // player aspect ratio > 16:9 and < 16:10 to accomodate the player controls without black bars surrounding the video
      embedWidth: 420,
      sampleMovieURL: null,
      sampleCensoredMovieURL: null,

      hostname: window.location.hostname,

      formVisible: false,
      sliderOptions: {
        min: 250,
        max: 1200,
        'tooltip-formatter': value => `${value} x ${this.embedHeight} px`,
        'tooltip-dir': 'bottom',
        tooltipStyle: {
          backgroundColor: '#A00956',
          borderColor: '#A00956',
        },
        processStyle: {
          backgroundColor: '#A00956',
        },
      },
    };
  },

  methods: {
    moviePlayerHTML(useCensored) {
      // i'd normally split this long line with \ at the end of each line for readability, but that
      // borks the copy/paste textblock when it's pasted
      return `<script name="setAffplayer${this.movieID}" id="setAffplayer${this.movieID}" type="text/javascript" src="https://affiliate.dtiserv.com/js/setAffplayerVideo2.js?affid=${this.affID}&siteid=${this.affSiteID}&video=${(useCensored) ? this.sampleCensoredMovieURL : this.sampleMovieURL}&img=https://${this.hostname}/assets/sample/${this.movieID}/${(this.locale === 'en') ? 'poster_en.jpg' : 'l_hd.jpg'}&w=${this.embedWidth}&h=${this.embedHeight}&url=${(this.locale === 'en') ? 'en' : 'www'}.pacopacomama.com&id=${this.movieID}&m_flag=1&page=movies/${this.movieID}/${(this.locale === 'en') ? '&lang=en' : ''}"><\/script>`;
    },

    replaceFilenameWithExtension(url, newFilename) {
      // Extract the path and filename from the URL
      const urlObj = new URL(url);
      const pathArray = urlObj.pathname.split('/');
      const oldFilenameWithExtension = pathArray.pop(); // Remove the old filename with extension
      // eslint-disable-next-line no-unused-vars
      const [oldFilename, oldExtension] = oldFilenameWithExtension.split('.');

      // Append the new filename and the original extension
      pathArray.push(`${newFilename}.${oldExtension}`);

      // Join the path elements back together and reconstruct the URL
      urlObj.pathname = pathArray.join('/');
      const newUrl = urlObj.toString();

      return newUrl;
    },

    async toggleForm() {
      if (!this.formVisible) {
        if (!this.hasUncensoredSample) {
          // "old" logic -- use movie url from movie details json. we reverse the list so we can
          // get the 1st item in the list which will be the best quality available
          const bfAPI = new BifrostAPI();
          const movieDetail = await bfAPI.getMovieDetail(this.$route.params.movieId);
          const movieURLs = movieDetail.SampleFiles.reverse();
          this.sampleCensoredMovieURL = movieURLs[0].URL.replace(/^http:\/\//i, 'https://');
        } else {
          // new logic (use 'sample.mp4' for uncensored and 'sample_m.mp4' for censored)
          this.sampleMovieURL = `https://smovie.pacopacomama.com/sample/movies/${this.movieID}/sample.mp4`;
          this.sampleCensoredMovieURL = `https://smovie.pacopacomama.com/sample/movies/${this.movieID}/sample_m.mp4`;
        }
      }

      this.formVisible = !this.formVisible;
    },

    copyToClipboard(htmlCode) {
      if (htmlCode && htmlCode !== '') {
        this.$copyText(htmlCode).then(() => {
          // copy succesful
          EventBus.$emit('notification:event:generic', {
            text: this.$t('copied_notification'),
            duration: 3000,
          });
        }, (e) => {
          console.log('%c copyToClipboard failed!', 'color:#f00', e);
        });
      }
    },

    tabChangeHandler() {
    },
  },

  computed: {
    locale() {
      return this.$i18n.locale;
    },
    embedHeight() {
      return Math.round(this.embedWidth / this.embedRatio);
    },
    imageBannerHTML() {
      return `<a href="https://click.dtiserv2.com/${(this.locale === 'en') ? 'eDirect' : 'Direct'}/8881889-${this.affSiteID}-${this.affID}/movies/${this.movieID}/" target="_blank"><img src="https://${this.hostname}/assets/sample/${this.movieID}/${(this.locale === 'en') ? 'poster_en.jpg' : 'l_hd.jpg'}" border="0" width="${this.embedWidth}" height="${this.embedHeight}"></a>`;
    },
    hasUncensoredSample() {
      // r3857 encoder was updated to process both censored and uncensored sample movies. this is the earliest movie
      // that was uploaded after the new encoder was patched
      const CUTOFF_EPOCH_MSEC = APP_CONFIG.site.uncensoredSampleStartEpoch;

      // regex match the pattern "xxxxxx_xxx"
      const movieDateFormat = this.movieID.match(/^(\d{6})_(\d{3})$/);

      // compare movie release date to the cutoff date; return true if it's after the cutoff
      if (movieDateFormat) {
        const datePart = movieDateFormat[1];
        const month = parseInt(datePart.slice(0, 2), 10);
        const day = parseInt(datePart.slice(2, 4), 10);
        const year = parseInt(`20${datePart.slice(4, 6)}`, 10);
        const dateObject = new Date(year, month - 1, day);
        const epochTime = dateObject.getTime();
        return (epochTime >= CUTOFF_EPOCH_MSEC);
      }

      // movie date format is not what we're expecting...  which prob means it's after the year 2100, so if we're still
      // generating content by then it will probably have uncensored movies too, so just return true
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/pages/_detail.scss';

.embed-code {
  margin-bottom: 1.5rem;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 4px;
  padding: 10px;
  background: rgba(0,0,0,.04);
  font-family: monospace;
  font-size: 14px;
  word-break: break-all;
}

.embed-contents {
  margin-bottom: 1rem;
}

.aff-embed-enter-active, .aff-embed-leave-active {
  transition: opacity 1s;
}
.aff-embed-enter, .aff-embed-leave-to {
  opacity: 0;
}
</style>
