import { render, staticRenderFns } from "./relatedMovies.vue?vue&type=template&id=436f86a5&scoped=true&"
import script from "./relatedMovies.vue?vue&type=script&lang=js&"
export * from "./relatedMovies.vue?vue&type=script&lang=js&"
import style0 from "./relatedMovies.vue?vue&type=style&index=0&id=436f86a5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "436f86a5",
  null
  
)

/* custom blocks */
import block0 from "./relatedMovies.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports