module.exports = {
  movieGallery: {
    sampleURLs: {
      preview: '/assets/sample/{MOVIE_ID}/s/{FILENAME}',
      fullsize: '/assets/sample/{MOVIE_ID}/l/{FILENAME}',
      movieIdKey: 'MovieID',
    },
    memberURLs: {
      preview: '/assets/sample/{MOVIE_ID}/s/{FILENAME}',
      fullsize: '/assets/member/{MOVIE_ID}/l/{FILENAME}',
      movieIdKey: 'MovieID',
    },
  },
};
