<i18n>
{
  "en": {
    "related_videos": "Related Videos",
    "vip_badge": "VIP",
    "svip_badge": "SVIP",
    "annual_badge": "Annual",
    "limited_tag": "Limited Availability",
    "expired_tag": "Availability Ended",
    "future_tag": "Upcoming"
  },
  "ja": {
    "related_videos": "関連動画",
    "vip_badge": "VIP",
    "svip_badge": "超VIP",
    "annual_badge": "年間",
    "limited_tag": "期間限定",
    "expired_tag": "配信期間終了",
    "future_tag": "近日配信"
  }
}
</i18n>

<template>
  <div class="movie-related">
    <h2 class="is-primary">{{ $t('related_videos') }}</h2>
    <div class="grid is-movie">
      <div class="grid-item" v-for="(movie, index) in movieListRelated" :key="movie.MovieID">
        <router-link class="entry" :to="`/movies/${movie.MovieID}/`">
          <div class="entry-media">
            <div v-if="!newXPEligible && movie.isVIP" class="tag-member is-vip">{{ $t('vip_badge') }}</div>
            <div v-if="!newXPEligible && movie.isSVIP" class="tag-member is-svip">{{ $t('svip_badge') }}</div>
            <div v-if="!newXPEligible && movie.isAnnual" class="tag-member is-annual">{{ $t('annual_badge') }}</div>
            <div class="media-thum">
              <img class="media-image" :src="`${movie.thumbnailSrc}`"  @error="useFallbackImage(index)" alt="" v-sfw>
            </div>
          </div>
          <div class="entry-meta">
            <div v-if="movie.IsLimited" class="tag-status is-limited">{{ $t('limited_tag') }}</div>
            <div v-if="movie.IsExpired" class="tag-status is-expired">{{ $t('expired_tag') }}</div>
            <div v-if="movie.IsComingSoon" class="tag-status is-future">{{ $t('future_tag') }}</div>
            <div class="meta-data">{{ movie.Release }}</div>
              <!-- actress -->
              <!-- multiple actress names spacing -> jp: space / en: comma + space -->
              <!-- need 2 x '&#32;' to get 1 space -->
              <div class="meta-title" v-if="locale == 'ja'"><span v-for="(actress, index) in movie.ActressesJa" :key="index">{{ actress }}<span v-if="index + 1 < movie.ActressesJa.length">&#32;&#32;</span></span></div>
              <div class="meta-title" v-if="locale == 'en'"><span v-for="(actress, index) in movie.ActressesEn" :key="index">{{ actress }}<span v-if="index + 1 < movie.ActressesEn.length">,&#32;</span></span></div>
              <!-- age -->
              <age-tag :movie="movie" />
          </div>
        </router-link>
      </div>
    </div><!-- /.grid -->
  </div>
</template>

<script>
/* eslint max-len: 0 */

import BifrostAPI from '@/assets/js/services/Bifrost/API';
import MovieHelper from '@/assets/js/utils/movie';
import AgeTag from '@/components/_common/ageTag.vue';

export default {
  props: {
    numMovies: Number,
  },
  components: {
    'age-tag': AgeTag,
  },
  data() {
    return {
      movieListRelated: [],
      isDisabled: false,
      posterFallbackTries: {},
    };
  },
  created() {
    this.getRelatedMovies(this.userTypeID);
  },
  methods: {
    async getRelatedMovies(userTypeID) {
      const bfAPI = new BifrostAPI();
      // get movie data + related movie info if we're on a movie detail page
      if (this.$route.name === 'movie-detail') {
        const movie = await bfAPI.getMovieDetail(this.$route.params.movieId);
        if (Object.prototype.hasOwnProperty.call(movie, 'MetaMovieID')) {
          const cats = await bfAPI.getCatAssoc(movie.MetaMovieID);
          const movieListRelated = MovieHelper.expandDetailsList(cats.Rows.slice(0, parseInt(this.numMovies, 10)), this.locale, userTypeID);
          this.movieListRelated = MovieHelper.filterExcludedMovieList(movieListRelated, userTypeID);
        }
      }
      // check actress age
      // hide age if there are more than 1 actress in actressList
      // only display age if it's set over '18' (prevent webmaster input error and ignore data of age'0' in db)
      for (let i = 0; i < this.movieListRelated.length; i += 1) {
        if (this.movieListRelated[i].ActressesList && Object.keys(this.movieListRelated[i].ActressesList).length === 1) {
          const firstActressID = Object.keys(this.movieListRelated[i].ActressesList)[0];
          if (this.movieListRelated[i].ActressesList[firstActressID].Age
              && this.movieListRelated[i].ActressesList[firstActressID].Age >= 18) {
            this.$set(this.movieListRelated[i], 'actressAge', this.movieListRelated[i].ActressesList[firstActressID].Age);
          }
        }
      }
    },
    async loadMore() {
      this.posterFallbackTries = {}; // reset fallback states
      const bfAPI = new BifrostAPI();

      const movie = await bfAPI.getMovieDetail(this.$route.params.movieId);
      if (Object.prototype.hasOwnProperty.call(movie, 'MetaMovieID')) {
        const cats = await bfAPI.getCatAssoc(movie.MetaMovieID);
        const movieListRelated = MovieHelper.expandDetailsList(cats.Rows, this.locale, this.userTypeID);
        this.movieListRelated = MovieHelper.filterExcludedMovieList(movieListRelated, this.userTypeID);
      }
      // hide "view more after click"
      this.isDisabled = !this.isDisabled;
    },
    useFallbackImage(index) {
      // missing movie poster thumbnail, fallback to large poster
      if (!this.posterFallbackTries[this.movieListRelated[index].MovieID]) {
        // attempt 1: poster thumbnail is missing, use large poster
        this.$set(this.movieListRelated[index], 'thumbnailSrc', `/assets/sample/${this.movieListRelated[index].MovieID}/str.jpg`);
        this.posterFallbackTries[this.movieListRelated[index].MovieID] = true;
      } else {
        // attempt 2: large poster is missing, use no-image placeholder
        this.$set(this.movieListRelated[index], 'thumbnailSrc', '/img/common/now_printing_square.png');
      }
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  watch: {
    userTypeID(newVal) {
      this.posterFallbackTries = {}; // reset fallback states
      this.getRelatedMovies(newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/pages/_detail.scss';
</style>
