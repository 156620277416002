<i18n>
{
  "en": {
    "like": "Favorite",
    "save": "Save",
    "mdl_title_save_to": "Save to...",
    "mdl_placeholder_create": "Give your playlist a name",
    "mdl_btn_create_new_playlist": "Create New Playlist",
    "mdl_btn_create_new_playlist_disabled": "Playlist limit reached",
    "mdl_btn_create": "Create",
    "ntf_add_playlist_0": "Added to playlist '",
    "ntf_add_playlist_1": "' successfully",
    "ntf_remove_playlist_0": "Removed from playlist '",
    "ntf_remove_playlist_1": "' successfully",
    "alert_name_exists": "This playlist name already exists",
    "alert_name_invalid": "Invalid playlist name"
  },
  "ja": {
    "like": "お気に入り",
    "save": "保存",
    "mdl_title_save_to": "保存先...",
    "mdl_placeholder_create": "再生リストの名前を入力",
    "mdl_btn_create_new_playlist": "新しい再生リストを作成",
    "mdl_btn_create_new_playlist_disabled": "再生リスト数の上限に達しました",
    "mdl_btn_create": "作成",
    "ntf_add_playlist_0": "「",
    "ntf_add_playlist_1": "」に追加しました",
    "ntf_remove_playlist_0": "「",
    "ntf_remove_playlist_1": "」から削除されました",
    "alert_name_exists": "この名前はすでに使用されています",
    "alert_name_invalid": "無効な名前です"
  }
}
</i18n>

<template>
<div class="playlist-menu">
  <span class="btn-like" @click="toggleLiked"><i :class="{ 'icon-heart': isLiked, 'icon-heart-o': !isLiked }"></i>{{ $t('like') }}</span>
  <span class="btn-save" :class="[saveBtnDisabled? 'disabled' : '']" @click="showPlaylistModal" :disabled="saveBtnDisabled"><i class="icon-playlist_add"></i>{{ $t('save') }}</span>

  <!-- add to playlist modal -->
  <modal name="ui-modal-playlist" class="modal-overwrite" draggable="false">
    <div class="playlist-modal">
      <div class="modal-close">
        <span class="modal-close-icon" @click="closePlaylistModal"></span>
      </div>
      <div class="modal-title">{{ $t('mdl_title_save_to') }}</div>
      <div class="modal-section">
        <ul>
          <li v-for="playlist in sortedFilteredPlaylists" :key="playlist.playlist_id">
            <input :name="`checkbox-${playlist.playlist_id}`" type="checkbox" :checked="playlist.checked" @change="updatePlaylist(playlist.playlist_id, playlist.name, playlist.checked)">
            <label :for="`checkbox-${playlist.playlist_id}`" @click="updatePlaylist(playlist.playlist_id, playlist.name, playlist.checked)">{{ playlist.type === 'default'? translationMap[playlist.name][locale] : playlist.name }}</label>
          </li>
        </ul>
      </div>
      <!-- create new playlist section -->
      <div class="modal-section center" v-if="customListsEnabled">
        <button
          class="button is-filled is-color-main is-small"
          v-if="!showInput && numCustomLists < customListLimit"
          @click="showInput = true">{{ $t('mdl_btn_create_new_playlist') }}</button>
        <button
          class="button is-filled is-color-main is-small"
          v-else-if="!showInput && numCustomLists >= customListLimit"
          disabled>{{ $t('mdl_btn_create_new_playlist_disabled') }}</button>
        <div class="section-input bottom" v-else>
          <input type="text" :placeholder="$t('mdl_placeholder_create')" :maxlength="maxChars" v-model="newPlaylistName" @focus="showAlert = false">
          <div class="limit">{{ maxChars - newPlaylistName.length }}/{{ maxChars }}</div>
          <div class="alert" v-if="showAlert">{{ $t(alertText) }}</div>
          <button class="button is-filled is-color-main is-small" :disabled="!newPlaylistName.length" @click="addToNewPlaylist(newPlaylistName)">{{ $t('mdl_btn_create') }}</button>
        </div>
      </div>
    </div>
  </modal>
</div>
</template>

<script>
/* eslint max-len: 0 */

import orderBy from 'lodash/orderBy';
import validator from 'validator/validator';
import Analytics from '@/assets/js/utils/Analytics';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import EventBus from '@/assets/js/utils/EventBus';

const LIKED_PLAYLIST_NAME = 'liked';
const bfAPI = new BifrostAPI();

export default {
  data() {
    return {
      metaMovieId: null,
      playlists: [],
      saveBtnDisabled: false, // flag for +save button click delay
      isLiked: false, // flag for 'liked' playlist toggles
      showInput: false, // flag for new playlist input
      showAlert: false, // flag for alert message
      alertText: '',
      newPlaylistName: '',
      movieTitle: '',
      actressName: '',
    };
  },
  created() {
    // non-reactive consts
    this.maxChars = 100;

    bfAPI.getMovieDetail(this.$route.params.movieId).then((response) => {
      // need meta Movie ID here to match Movie ID keys in playlist data
      this.metaMovieId = response.MetaMovieID;
      // need movie title/actress(es) for GA4 custom events
      this.movieTitle = response.Title;
      this.actressName = response.Actor;
      // get playlists for playlist modal
      this.getPlaylists();
    });
  },
  methods: {
    getPlaylists() {
      bfAPI.getPlaylists().then((response) => {
        if (response.status === 200) {
          this.playlists = response.data.data;

          let movieList = {};
          for (let i = 0; i < this.playlists.length; i += 1) {
            // this part is to pre-select playlist(s) that the movie has been added to already
            movieList = this.playlists[i].movies; // format: {"12345": 1, "22345": 1}
            const movieIds = Object.keys(movieList); // format: ['12345', '22345']

            for (let j = 0; j < movieIds.length; j += 1) {
              const movieId = parseInt(movieIds[j], 10);

              if (movieId === this.metaMovieId) {
                // for 'liked' playlist, set 'isLiked' flag to true to highlight the favorite icon
                if (this.playlists[i].name === LIKED_PLAYLIST_NAME && this.playlists[i].type === 'default') {
                  this.isLiked = true;
                }
                // set checkbox value = true if movie ID matches
                this.$set(this.playlists[i], 'checked', true);
              }
            }
          }
        }
      });
    },
    toggleLiked() {
      if (this.isLiked) {
        this.deleteMovieFromPlaylist(this.likedPlaylistId, LIKED_PLAYLIST_NAME);
      } else {
        this.addMovieToPlaylist(this.likedPlaylistId, LIKED_PLAYLIST_NAME);
      }
    },
    addMovieToPlaylist(playlistId, playlistName) {
      bfAPI.addMovieToPlaylist(playlistId, this.metaMovieId).then((response) => {
        if (response.status === 200) {
          // show notification
          EventBus.$emit('notification:event:generic', {
            text: this.$t('ntf_add_playlist_0') + ((typeof this.translationMap[playlistName] !== 'undefined') ? this.translationMap[playlistName][this.locale] : playlistName) + this.$t('ntf_add_playlist_1'),
            duration: 3000,
          });

          // only for 'liked' playlist, update 'isLiked' flag value
          if (playlistId === this.likedPlaylistId) {
            this.isLiked = true;
            this.$analytics.trackEvent('Playlist', 'Add Movie to "Favorites"', this.$route.params.movieId);
          } else {
            this.$analytics.trackEvent('Playlist', 'Add Movie to Playlist', this.$route.params.movieId);
          }

          // analytics
          Analytics.sendCustomEvent('playlist', {
            playlist_action: 'Add Movie to Playlist',
            playlist_type: (typeof this.translationMap[playlistName] !== 'undefined') ? 'Default' : 'Custom',
            playlist_name: (typeof this.translationMap[playlistName] !== 'undefined') ? this.translationMap[playlistName][this.locale] : playlistName,
            movie_id: this.$route.params.movieId,
            movie_title: this.movieTitle,
            actress_name: this.actressName,
          });

          // get updated playlists for modal
          this.getPlaylists();
        }
      });
    },
    deleteMovieFromPlaylist(playlistId, playlistName) {
      bfAPI.deleteMovieFromPlaylist(playlistId, this.metaMovieId).then((response) => {
        if (response.status === 200) {
          // show notification
          EventBus.$emit('notification:event:generic', {
            text: this.$t('ntf_remove_playlist_0') + ((typeof this.translationMap[playlistName] !== 'undefined') ? this.translationMap[playlistName][this.locale] : playlistName) + this.$t('ntf_remove_playlist_1'),
            duration: 3000,
          });

          // only for 'liked' playlist, update 'isLiked' flag value
          if (playlistId === this.likedPlaylistId) this.isLiked = false;

          // analytics
          Analytics.sendCustomEvent('playlist', {
            playlist_action: 'Delete Movie from Playlist',
            playlist_type: (typeof this.translationMap[playlistName] !== 'undefined') ? 'Default' : 'Custom',
            playlist_name: (typeof this.translationMap[playlistName] !== 'undefined') ? this.translationMap[playlistName][this.locale] : playlistName,
            movie_id: this.$route.params.movieId,
            movie_title: this.movieTitle,
            actress_name: this.actressName,
          });

          // get updated playlists for modal
          this.getPlaylists();
        }
      });
    },
    updatePlaylist(playlistId, playlistName, isChecked) {
      if (!isChecked) {
        this.addMovieToPlaylist(playlistId, playlistName);
      } else {
        this.deleteMovieFromPlaylist(playlistId, playlistName);
      }
    },
    addToNewPlaylist(playlistName) {
      // sanitize user input
      let plName = this.sanitizeInput(playlistName);

      if (plName === '') {
        // if sanitizeInput() returns empty, meaning input contains only illegal script tags
        // which have been replaced completey; clear input box and display alert msg
        this.newPlaylistName = '';
        this.showAlert = true;
        this.alertText = 'alert_name_invalid';
      } else {
        // replace multiple whitespaces with 1 whitespace to prevent extra spaces between words
        plName = plName.replace(/\s+/g, ' ');
        // check if user input playlist name is same as our default playlist names (case insensitive)
        Object.keys(this.translationMap).forEach((pl) => {
          if (plName.toLowerCase() === this.translationMap[pl][this.locale].toLowerCase()) {
            this.newPlaylistName = plName;
            this.showAlert = true;
            this.alertText = 'alert_name_exists';
          }
        });
      }

      if (!this.showAlert) {
        // 2 steps here: first create new playlist, then add the movie to this new playlist
        bfAPI.createPlaylist('custom', plName, false).then((response) => {
          // if a new playlist is created you'll see the data property
          // otherwise if the playlist already exists you'll just get the playlistCount property
          if (response.status === 201 && response.data.data) {
            const newPlaylistId = response.data.data.playlist_id;
            this.addMovieToPlaylist(newPlaylistId, plName, this.selectedMovieId);
            this.closePlaylistModal();

            // analytics
            this.$analytics.trackEvent('Playlist', 'Create Playlist', plName);
            Analytics.sendCustomEvent('playlist', {
              playlist_action: 'Add Playlist',
              playlist_type: 'Custom',
              playlist_name: plName,
            });
          } else if (response.status === 201 && !response.data.data) {
            this.newPlaylistName = plName;
            this.showAlert = true;
            this.alertText = 'alert_name_exists';
          } else if (response.status === 400 && response.data.error === 'playlist_name invalid') {
            this.newPlaylistName = '';
            this.showAlert = true;
            this.alertText = 'alert_name_invalid';
          }
        });
      }
    },
    sanitizeInput(input) {
      // trim(): trim characters (whitespace by default) from both sides of the input
      // escape(): replace <, >, &, ', " and / with HTML entities
      let cleanInput = input.replace(/(<([^>]+)>)/ig, '');
      cleanInput = validator.trim(cleanInput);
      cleanInput = validator.escape(cleanInput);

      return cleanInput;
    },
    showPlaylistModal() {
      if (this.saveBtnDisabled) {
        return;
      }
      // +save button click delay; disable button for 5 seconds before the user can click again
      this.saveBtnDisabled = true;
      this.timeout = setTimeout(() => {
        this.saveBtnDisabled = false;
      }, 5000);

      this.resetPlaylistModal();
      this.getPlaylists();
      this.$modal.show('ui-modal-playlist');
    },
    closePlaylistModal() {
      this.resetPlaylistModal();
      this.$modal.hide('ui-modal-playlist');
    },
    resetPlaylistModal() {
      this.showInput = false;
      this.showAlert = false;
      this.newPlaylistName = '';

      // reset checkbox value = false
      for (let i = 0; i < this.playlists.length; i += 1) {
        this.playlists[i].checked = false;
      }
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    numCustomLists() {
      return this.playlists.filter(playlist => playlist.type === 'custom').length;
    },
    customListLimit() {
      return this.$store.getters['playlist/custom_limit'];
    },
    customListsEnabled() {
      return this.$store.getters['playlist/isCustomListsEnabled'];
    },
    translationMap() {
      return this.$store.getters['playlist/translationMap'];
    },
    sortedFilteredPlaylists() {
      // sort playlists by default types first (desc order), and then playlist name by alpha (asc
      // order), and then filter if applicable
      const orderedList = orderBy(this.playlists, ['type', 'name'], ['desc', 'asc']);
      const filteredList = (!this.customListsEnabled) ? orderedList.filter(item => item.type === 'default') : orderedList;

      return filteredList;
    },
    defaultPlaylistIds() {
      return this.$store.getters['playlist/defaultPlaylistIDs'];
    },
    likedPlaylistId() {
      return this.defaultPlaylistIds[LIKED_PLAYLIST_NAME];
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_playlist.scss';
</style>
